.App {
  text-align: center;
}

.jumbotron {
  /*background: linear-gradient(#095141, #192827);*/
  background-image: url("./leaves.svg"), radial-gradient(circle at center, #365902, #172601);
  background-size: auto 70%, auto;
  background-blend-mode: darken;
  background-repeat: repeat-x;
  background-position: center;
  /* background-image: url('green-slate.jpg'); */
  color: white;
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #172601;
}

.jumbotron-text {
  font-family: 'Bellota Text', cursive;
}

.cursive-text {
  font-family: 'Dancing Script', cursive;
}

.separator {
  height: 7.5em;
  padding-top: 2em;
}

ul {
  list-style-type: none;
}

hr {
  height: 6px;
  background-image: radial-gradient(
    closest-side,
    hsla(0, 0%, 50%, 1.0),
    hsla(0, 0%, 50%, 0) 100%);
  position: relative;
}
hr:after {
  position: absolute;
  top:  50%;
  left: 50%;
  display:block;
  background-color: hsl(0, 0%, 75%);
  height: 12px;
  width:  12px;
  transform: rotate(45deg);
  margin-top:  -10px;
  margin-left: -10px;
  border-radius: 4px 0;
  border: 4px solid hsla(0, 0%, 100%, 0.35);
  background-clip: padding-box;
  box-shadow: -10px 10px 0 hsla(0, 0%, 100%, 0.15), 10px -10px 0 hsla(0, 0%, 100%, 0.15);
}

.story-container {
  padding-top: 2rem;
}

#our-story {
  margin: auto;
  max-width: 800px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




